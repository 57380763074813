/* ------------------------------ */
/* General
/* ------------------------------ */
body.loading:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.3);
    z-index: 9998;
}
body.loading:after {
    content: '';
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
    background-image: url(../../public/assets/img/loader.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 9999;
    background-position-y: center;
}
main {
    font-family: Montserrat;
    display: block;
    left: 0;
    margin: 0 auto;
}
.clear-margin-padding {
    margin: 0 !important;
    padding: 0 !important;
}
.text-center {
    text-align: center;
}
.half-width {
    width: 50%;
}
.full-width {
    width: 100%;
}
.hide {
    display: none !important;
}
.show {
    display: block;
}
.container {
    max-width: 100%;
    padding: 18px;
}
.pac-container {
    z-index: 3333;
    background-color: #ffffff;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.row-center {
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
}
.flex-grow {
    flex-grow: 1;
}
.space-between {
    justify-content: space-between;
}
.space-around {
    justify-content: space-around;
}
.relative {
    position: relative;
}
.full-center {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-15 {
    margin: 15;
}
/* .center {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.cat-label {
    background-color: #000000;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 12px;
    top: 0px;
}
header {
    max-width: 500px;
    margin: 0 auto;
}
@media (max-width: 767px) {
    main {
        max-width: 100vw;
        overflow-x: hidden;
    }
}
.cms-container {
    padding: 0 16px;
    text-align: justify;
}
.cms-container .cms-content-important {
    background: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
}
.cms-container a {
    color: #006bb4;
}
.cms-container a:hover,
.cms-container a:active {
    color: #444;
}
.cms-container .social-icon {
    padding: 0 6px;
    vertical-align: middle;
    display: inline-block;
}
.cms-container .social-icon i:before {
    content: '';
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 40px;
    display: block;
}
.cms-container .social-icon .icon-facebook:before {
    background-image: url(../../public/assets/img/facebook.png);
}
.cms-container .social-icon .icon-twitter:before {
    background-image: url(../../public/assets/img/twitter.png);
}
.cms-container .social-icon .icon-instagram:before {
    background-image: url(../../public/assets/img/instagram.png);
}
.cms-container .social-icon .icon-pinterest:before {
    background-image: url(../../public/assets/img/pinterest.png);
}
.wrapper-brands .slick-track{
    display: flex;
    align-items: center;
}

.grid {
    background-color: #ffffff;
}

.collapsible {
    background-color: #DEDEDE;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.active, .collapsible:hover {
    background-color: #F8F8F8;
}

.content-collapsible {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #FFFFFF;
}

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.keen-slider {
    padding: 0px 0px 2px !important;
}

.navigation-wrapper {
    position: relative;
  }
  
  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }
  
  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }
  
  .dot:focus {
    outline: none;
  }
  
  .dot.active {
    background: #000;
  }
  
  .arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #161616;
    background-color: #FFF;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .arrow--left {
    left: 5px;
    padding: 0px 3px 0px 0px;
  }
  
  .arrow--right {
    left: auto;
    right: 5px;
  }
  
  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
    background-color: transparent;
  }  

@media screen and (min-width: 768px) and (max-width: 1023px) {    
    .full-height .mgz-column > .mgz-element {
        padding-top: 0px !important;
        padding-left: 0px !important;
    }
}

@media screen and (min-width: 1024px) {
    .mgz-element > div > p {
        margin: 0 !important;
    }
    
    .full-height .mgz-column > .mgz-element {
        padding-top: 0px !important;
        padding-left: 0px !important;
    }

    .main-app {
        width: 100% !important;
    }
}

.MuiPaper-elevation1, .MuiPaper-elevation0 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.08),0px 1px 1px 0px rgba(0,0,0,0.08),0px 1px 3px 0px rgba(0,0,0,0.08) !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }